import React from 'react';
import './App.css';

const App = () => {
  return (
      <div className="container">
        <header>
            <div className="logoContainer">
              <img src="logo/logo.png" alt="Logo" className="logo" />
              <img src="logo/text-banner.png" alt="Logo" className="logo" />
            </div>
          <h2>VIDEO GAME DESIGN / DEVELOPMENT SOLUTIONS</h2>
        </header>
        <main>
          <h2 className="soon">COMING SOON...</h2>
        </main>
        <footer>
            <h3 className="queries">Queries / Issues: </h3>
            <h1 className="email">admin@VanguardGames.co.uk</h1>
          <p className="copyright">
            &copy; {new Date().getFullYear()} Vanguard Games Ltd. All rights reserved.
          </p>
        </footer>
      </div>
  );
};

export default App;
